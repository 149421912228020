import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../components/common/Breadcrumbs'
import { DownloadOutlined, QrcodeOutlined } from '@ant-design/icons'

const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Magic Paints" breadcrumbItem="Dashboard" />

          <Row>
            <Col sm={3}>
              <Card className='bg-primary'>
                <CardBody>
                  <div className="d-flex text-muted">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div className="avatar-sm">
                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <DownloadOutlined />
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden text-white">
                      <h5 className="mb-1 mt-1 text-white">435</h5>
                      <p className="mb-1">Total App Downloaded</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={3}>
              <Card className='bg-warning'>
                <CardBody>
                  <div className="d-flex text-muted">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div className="avatar-sm">
                        <div className="avatar-title bg-light rounded-circle text-warning font-size-20">
                          <i className="mdi mdi-account-group-outline"></i>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden text-white">
                      <h5 className="mb-1 mt-1 text-white">235</h5>
                      <p className="mb-1">Total Active Dealers</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={3}>
              <Card className='bg-success'>
                <CardBody>
                  <div className="d-flex text-muted">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div className="avatar-sm">
                        <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                          ₹
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden text-white">
                      <h5 className="mb-1 mt-1 text-white">18,87,600</h5>
                      <p className="mb-1">QR Scanned Amount</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={3}>
              <Card className='bg-info'>
                <CardBody>
                  <div className="d-flex text-muted">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div className="avatar-sm">
                        <div className="avatar-title bg-light rounded-circle text-info font-size-20">
                        <QrcodeOutlined />
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden text-white">
                      <h5 className="mb-1 mt-1 text-white">87,600</h5>
                      <p className="mb-1">Total QR Scanned</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={3}>
              <Card className='bg-danger'>
                <CardBody>
                  <div className="d-flex text-muted">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div className="avatar-sm">
                        <div className="avatar-title bg-light rounded-circle text-danger font-size-20">
                        <QrcodeOutlined />
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden text-white">
                      <h5 className="mb-1 mt-1 text-white">87,600</h5>
                      <p className="mb-1">Batch Activated</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard