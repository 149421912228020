import endPoints from "../../config/apiList";
import {apiService as api} from "../apiService";

const addTagTypes=['login']
const schemeDetailAPI = api.enhanceEndpoints({
    addTagTypes
})
.injectEndpoints({
    endpoints:(builder)=>({
        addUpdateSchemeDetail:builder.mutation({
            query: (queryArg) => ({
                url: endPoints.schemeDetailAPI.AddUpdate,
                method: 'POST',
                data: queryArg,
                headers: {
                    'Content-Type':'application/json'
                }
            })
        }),
        getSchemeData:builder.mutation({
            query: (queryArg) => ({
                url: endPoints.schemeDetailAPI.getSchemeData,
                method: 'POST',
                data: queryArg,
                headers: {
                    'Content-Type':'application/json'
                }
            })
        }),
        getSchemeDetailList: (builder).query({
            query:(queryArg) => ({
                url: endPoints.schemeDetailAPI.list,
                method: 'POST',
                data: queryArg
            })
        }),
    })
})

export const {
    useGetSchemeDetailListQuery,
    useAddUpdateSchemeDetailMutation,
    useGetSchemeDataMutation
} = schemeDetailAPI
export default schemeDetailAPI;