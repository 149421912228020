import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

const Popup = props => {
    const {isOpen, closePopup, isSuccess,btnText,msg}=props
    return (
      <>
          <Modal zIndex={99999} centered className="popup" styles={{padding: '10px'}} open={isOpen} closeIcon={false} title={false} footer={false}>
              <div className="text-center">
                <div style={{fontSize:'25px'}}>
                {
                    isSuccess ?
                    <CheckCircleOutlined className="text-success"/>
                    :
                    <CloseCircleOutlined className="text-danger" />
                } 
                </div>
                <h5 className='mt-2'>{msg}</h5>

                <Button type='primary' onClick={closePopup}>{btnText}</Button>
              </div>
          </Modal>
      </>
    )
}

Popup.propTypes = {
    isOpen:PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    btnText:PropTypes.string.isRequired,
    msg:PropTypes.string.isRequired
}

export default Popup
