import { Image } from 'antd'
import React from 'react'
import { noImage } from '../../helpers/NoImage'

const ImageWithPreview= ({src,height,...rest}) => {
  return (
    <>
        <Image {...rest} src={src} height={height||150} preview={{closeIcon:false,destroyOnClose:true,zIndex:9999}} fallback={noImage}/>
    </>
  )
}

export default ImageWithPreview