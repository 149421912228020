const SchemeDetailHeader={
    PageName:'Scheme',
    pagination:true,
    horizontalScroll:'',
    fixHeader:true,
    width:'auto',
    gridColumn:()=>[
        {
            title:'Action',
            dataIndex:'action',
            type:'action',
            actionList:[
                {
                    title:'Edit',
                    key:'edit'
                }
            ],
            width:100
        },
        {
            title: "Scheme Name",
            dataIndex: "schemeName",
        },
        {
            title: "Points",
            dataIndex: "points",
            width:100
        },
        {
            title: "Gift Name",
            dataIndex: "gift",
        },
        {
            title: "Picture",
            dataIndex: "fileName",
            type:'image',
            width:100
        }
    ]
}
export default SchemeDetailHeader;