import React, { useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import DealerRegForm from './DealerRegForm'
import Loader from '../../components/loader/Loader'
import { useCreateDealerMutation } from '../../store/APIQuery/dealerAPI'
import { useLocation } from 'react-router-dom'
import DealerRegEditForm from './DealerRegEditForm'

const DealerReg = () => {
    const [isLoading,toggleLoading] = useState(false)
    const {state,pathname} = useLocation()
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Admin Task" breadcrumbItem="Dealer Registration" />
                    <Row className="mb-4">
                        <Col xl={12}>
                            <Card className="h-100">
                                <CardBody>
                                    {
                                        pathname!='/dealer-operation' ?
                                        <DealerRegForm setUpLoader={(status)=>toggleLoading(status)}/>
                                        :
                                        <DealerRegEditForm setUpLoader={(status)=>toggleLoading(status)} state={state} />
                                    }
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {
                    isLoading && 
                    <Loader />
                }
                
            </div>
        </React.Fragment>
    )
}

export default DealerReg