import { Input } from 'antd';
import React from 'react'
import ReactInputMask from 'react-input-mask';
export const maskList = {
  mobile: {
    mask:"9999999999",
    placeholder:"0000000000"
  },
  pan:{
    mask:"aaaaa9999a",
    placeholder:"XXXXX0000X"
  },
  gst:{
    mask:"99aaaaa9999a9a*",
    placeholder:"00XXXXX0000X9X*"
  },
  adhaar: {
    mask:"999999999999",
    placeholder:"000000000000"
  },
  pincode:{
    mask:'999999',
    placeholder:"000000"
  }
}
const DocInput = ({fieldType,...rest}) => {
  let mask = maskList[fieldType]
  return (
    <>
        <ReactInputMask {...rest} mask={mask.mask} maskChar={""}  className="text-uppercase react-mask" placeholder={mask.placeholder}>
        </ReactInputMask>
    </>
  )
}

export default DocInput