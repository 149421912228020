import { Button, DatePicker, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import FileBase64 from 'react-file-base64';
import ImageWithPreview from '../../components/Image/ImageWithPreview';
import { useGetSchemeListQuery } from '../../store/APIQuery/schemeAPI';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetStateListQuery, useGetZoneListQuery } from '../../store/APIQuery/masterAPI';
import EditableTable from '../../components/table/EditableTable';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import Popup from '../../components/popup/popup';

const SchemeDetailForm = (props) => {
    const [form] = Form.useForm()
    const { handleSubmit } = props;
    const [fileData, setFile] = useState("");
    const [StatesName, setStatesName] = useState("");
    const [ZoneName, setZoneName] = useState("");

    const [schemeDropdown, setSchemeDropdown] = useState([]);
    const { data: schemeData, refetch } = useGetSchemeListQuery()
    const {data:zoneList} = useGetZoneListQuery()
    const {data:stateList} = useGetStateListQuery()
    const navigate = useNavigate()

    const [gistList, setGiftList]=useState([{
        key:1,
        gift:"",
        point:null,
        giftImageName:""
    }])
    const [{ showPopup, status, msg }, togglePopup] = useState({ showPopup: false, status: false, msg: "" })

    const colDef = [
        {
            title: ()=>{
                return <><span className="text-danger">*</span> Gift Name</>
            },
            dataIndex: "gift",
            editable: true,
            isRequired: true,
            inputType: 'text'
        },
        {
            title: ()=>{
                return <><span className="text-danger">*</span> Point slab</>
            },
            dataIndex: "point",
            editable: true,
            isRequired: true,
            inputType: 'number'
        },
        {
            title: "Gift Image",
            dataIndex: "giftImageName",
            editable: true,
            isRequired: false,
            inputType: 'fileUpload'
        },
        {
            title: "#",
            dataIndex: "action",
            editable: false,
            render: (_, record) => {
                if (record.key > 1) {
                    return <span className='addIcon text-danger' onClick={() => removeItem(record.key)}><DeleteFilled /></span>
                }
                return <span className='addIcon' onClick={() => addNewRow()}><PlusOutlined /></span>
            }
        }
    ]

    const removeItem=(key, type) => {
            const filteredPeople = gistList.filter((item) => item.key !== key);
            setGiftList(filteredPeople)
    }

    const addNewRow=(type)=>{
        let list = [...gistList]
        list.push({
            key:list.length+1,
            gift:"",
            point:null,
            giftImageName:""
        })
        setGiftList(list)
    }


    useEffect(() => {
        if (schemeData?.data) {
            let arr = [];
            schemeData.data.map((item) => {
                arr.push({
                    label: item.schemeName,
                    value: item.schemeId
                })
            })
            setSchemeDropdown(arr)
        }
    }, [schemeData])


    const handleFinish = (formdata) => {
        const { SchemeId } = formdata
        let prevpoint=0;
        let isError = false;
        let params=[]
        for (let index = 0; index < gistList.length; index++) {
            const element = gistList[index];
            if(parseInt(prevpoint)>parseInt(element.point)){
                isError=true
                break;
            }
            else{
                prevpoint=gistList[index].point
                params.push({
                    id: "",
                    mode: "I",
                    gift: gistList[index].gift,
                    giftImageName: gistList[index].giftImageName,
                    schemeId: SchemeId,
                    point: gistList[index].point
                })
            }
        }

        handleSubmit(params,isError)
    }

    useEffect(() => {
        // if(operation=='update'){
        //     getSchemeData(state)
        // }
    }, [])

    const SchemeChange=(data)=>{
        let scheme = schemeData.data.filter((item) =>item.schemeId==data)[0];
        setZoneName(scheme.zone);

        let stateIds = scheme.state.split(',');
        let sName = [];
        stateList.data.map((item)=>{
            if(stateIds.indexOf(item.stateId)>-1){
                sName.push(item.stateName) 
            }
        })
        setStatesName(sName.join())
    }

    const setDataInRows=(rowType, name, value, key)=>{
        let dataArr = [...gistList]
        dataArr.map((item) => {
            if (item.key === key) {
                item[name] = name=='giftImageName'?value.base64:value
            }
        })
        setGiftList(dataArr);
    }

    const closePopup=()=>{
        togglePopup({
          showPopup: false,
          status: false,
          msg: ""
        })
        navigate('/scheme-detail',{state:{refresh:true}})
      }
    return (
        <React.Fragment>
            <Form layout='vertical' onFinish={handleFinish}>
                <Row>
                    <Col sm={4}>
                        <Form.Item name={`SchemeId`} label="Scheme" rules={[{ required: true }]} >
                            <Select placeholder="select Scheme" options={schemeDropdown} onChange={SchemeChange}>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col sm={4}>
                        <Form.Item label="Zone"  >
                            <Input value={ZoneName} disabled/>
                        </Form.Item>
                    </Col>
                    <Col sm={4}>
                        <Form.Item label="State"  >
                            <Input value={StatesName} disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <EditableTable 
                            columns={colDef}
                            rowData={gistList}
                            onChange={setDataInRows}
                            tableType={`director`}
                        />
                    </Col>
                </Row>
                {/* <Row>
                    <Col sm={4}>
                        <label>Grif Picture</label>
                        {
                            fileData &&
                            <div className="text-center mb-3">
                                <ImageWithPreview src={fileData} />
                            </div>
                        }
                        <FileBase64
                            multiple={false}
                            onDone={(file) => setFile(file.base64)}
                        />
                    </Col>
                </Row> */}
                <Row style={{ marginTop: '25px' }}>
                    <Col sm={12} className="text-center">
                        <Button htmlType='submit' type="primary">Submit</Button>&nbsp;&nbsp;
                        {/* <Button type="primary" className='bg-success'>Hold</Button>&nbsp;&nbsp; */}
                        <Button type="primary" danger onClick={() => navigate('/scheme-detail')}>Cancel</Button>
                    </Col>
                </Row>
            </Form>
            <Popup
              isOpen={showPopup}
              isSuccess={status}
              msg={msg}
              closePopup={closePopup}
              btnText='Close'
          />
        </React.Fragment>
    )
}

export default SchemeDetailForm