import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, NavItem, Row } from 'reactstrap'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import CustomTable from '../../components/table/CustomTable'
import DealerHeader, { data } from '../../gridHeader/DealerHeader'
import { useGetDealerListQuery } from '../../store/APIQuery/dealerAPI'
import Loader from '../../components/loader/Loader'
import Popup from '../../components/popup/popup'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserId } from '../../helpers/helper'

const DealerApplication = () => {
    const [dealerData, setDealerList] = useState([])
    const { isLoading, data, isSuccess, refetch } = useGetDealerListQuery({ registeredBy: getUserId() })
    const [{showPopup,success,msg}, togglePopup] = useState({success: false,msg:"",showPopup:false})
    const navigate = useNavigate()
    const { state } = useLocation()
    useEffect(()=>{
        if(state?.refetch){
            refetch()
        }
    },[])
    useEffect(() => {
        if (data?.success) {
            setDealerList(data.data?.filter(item=>item.status==""))
        }
    }, [data])

    const handleClick = (type, data) => {
        console.log(type, data)
        if(type=='Edit' && data.status=="A"){
            togglePopup({
                showPopup:true,
                success:false,
                msg:"This dealer already approved, You are not allowed to update data."
            })
        }
        else if((type=='Edit' && data.status=="") || (type=='view')){
            navigate('/dealer-operation',{state:{data:data.reqNo,type:type}})
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Dealer" breadcrumbItem="Pending Dealer List" />
                    <Row className="mb-4">
                        <Col xl={12}>
                            <Card className="h-100 b-0">
                                <CardBody>
                                    <CustomTable
                                        column={DealerHeader.gridColumn()}
                                        dataSource={dealerData}
                                        handleClick={handleClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {
                    isLoading &&
                    <Loader />
                }

                <Popup
                    isOpen={showPopup}
                    isSuccess={success}
                    msg={msg}
                    closePopup={() => togglePopup(false)}
                    btnText='Close'
                />

            </div>
        </React.Fragment>
    )
}

export default DealerApplication