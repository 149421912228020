import endPoints from "../../config/apiList";
import {apiService as api} from "../apiService";

const addTagTypes=['login']
const masterAPI = api.enhanceEndpoints({
    addTagTypes
})
.injectEndpoints({
    endpoints:(builder)=>({
        getStateList: (builder).query({
            query:(queryArg) => ({
                url: endPoints.state,
                method: 'POST',
                data: {"zoneId": "0"}
            })
        }),
        getZoneList: (builder).query({
            query:(queryArg) => ({
                url: endPoints.zone,
                method: 'POST'
            })
        })
    })
})

export const {
    useGetStateListQuery,
    useGetZoneListQuery
} = masterAPI
export default masterAPI;