import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'

const Error404 = () => {
    return (
        <> 
        <div className="page-content">
        <Container fluid={true} className="mt-5">
                <Row className="justify-content-center">
                    <Col >
                        <div className="text-center">
                            <div>
                                <h1 className="display-2 error-text fw-bold">4<i className="ri-ghost-smile-fill align-bottom text-primary mx-1"></i>4</h1>
                            </div>
                            <div>
                                <h4 className="text-uppercase mt-4">Sorry, page not found</h4>
                                <p>The page you are looking for is not available. Click below button to go back to Home page.</p>
                                <div className="mt-4">
                                    <Link to="/dashboard" className="btn btn-primary">Back to Home<i className="ri-arrow-right-line align-bottom ms-2"></i></Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default Error404