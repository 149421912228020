import endPoints from "../../config/apiList";
import {apiService as api} from "../apiService";

const addTagTypes=['login']
const dealerAPI = api.enhanceEndpoints({
    addTagTypes
})
.injectEndpoints({
    endpoints:(builder)=>({
        createDealer:builder.mutation({
            query: (queryArg) => ({
                url: endPoints.dealerAPI.create,
                method: 'POST',
                data: queryArg,
                headers: {
                    'Content-Type':'application/json'
                }
            })
        }),
        getDealerList: (builder).query({
            query:(queryArg) => ({
                url: endPoints.dealerAPI.list,
                method: 'POST',
                data: queryArg
            })
        }),
        getDealerDetailByGST:builder.mutation({
            query: (queryArg) => ({
                url: endPoints.dealerAPI.detailByGST,
                method: 'POST',
                data: queryArg,
                headers: {
                    'Content-Type':'application/json'
                }
            })
        }),
        getDealerDetailByReqNo:builder.mutation({
            query: (queryArg) => ({
                url: endPoints.dealerAPI.detailByReqNo,
                method: 'POST',
                data: queryArg,
                headers: {
                    'Content-Type':'application/json'
                }
            })
        }),
        setDealerStatus:builder.mutation({
            query: (queryArg) => ({
                url: endPoints.dealerAPI.dealerAction,
                method: 'POST',
                data: queryArg,
                headers: {
                    'Content-Type':'application/json'
                }
            })
        }),
    })
})

export const {
    useCreateDealerMutation,
    useGetDealerListQuery,
    useGetDealerDetailByGSTMutation,
    useGetDealerDetailByReqNoMutation,
    useSetDealerStatusMutation
} = dealerAPI
export default dealerAPI;