const endPoints = {
    login:`/DoLogin/signin`,
    dealerAPI:{
        create:`/DealerRegister/RegisterDealer`,
        list:`/DealerDetailsForGrid/DealerDetailsForGrid`,
        detailByGST:"/DealerVerificationByGst/check",
        detailByReqNo:"/GetDealerDetailsByReqNo/GetDelaerDetailsByReqNo",
        dealerAction:"/UpdateDealerReq/UpdateDetailsStatus"
    },
    schemeAPI:{
        list:`/GetSchemesForGrid/GetSchemeForGrid`,
        AddUpdate:"/SaveSchemeMaster/SchemeMaster",
        getSchemeData:'/EditSchemeMasterById/EditSchemeMasterById'
    },
    schemeDetailAPI:{
        list:`/GetSchemeDetailsForGrid/GetSchemeDetailsForGrid`,
        AddUpdate:"/SaveSchemeDetail/SchemeDetail",
        getSchemeData:'/EditSchemeMasterById/EditSchemeMasterById'
    },
    state:`/GetState/GetState`,
    zone:`/GetZone/GetZone`
}
export default endPoints