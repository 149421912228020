import { EditOutlined, EyeOutlined, UnorderedListOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Tooltip } from "antd"
import React from "react"
import { Badge } from "reactstrap"
import ImageWithPreview from "../Image/ImageWithPreview"

export const DateRenderer = (date) => {
  return (
    <>{date}</>
  )
}

export const ActionRenderer = ({ actionList, handleClick, record }) => {
 
  return (
    <React.Fragment>
      <div className="action-icon text-center">
        {
          actionList && actionList.map((item,key)=>{
            return  <Tooltip placement="top" title={item.title} >
                {
                  item.key=='edit' ?
                  <EditOutlined onClick={() => handleClick(item.title, record)} />
                  :
                  item.key=='view' ?
                  <EyeOutlined onClick={() => handleClick('view', record)} />
                  :
                  null
                }
              </Tooltip>
          })
        }
        
        {/* <Tooltip placement="top" title={`View`} >
          <EyeOutlined onClick={() => handleClick('view', record)} />
        </Tooltip> */}
      </div>
    </React.Fragment>
  )
}

export const StatusRenderer = (data) => {
  return (
    <>
      <h5><Badge color={data == 'Approved' ?"success":data==""?"warning":"danger" }>{data==""?"Pending":data}</Badge></h5>
    </>
  )
}

export const ImageRenderer = (data) => {
  return <><ImageWithPreview src={data} height={60}/></>
}