/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import {Form, Input, notification} from 'antd'

import logolight from "../../assets/images/logo.png";
import { useUserLoginMutation } from '../../store/APIQuery/authAPI';
import Loader from '../../components/loader/Loader';
import { baseURL } from '../../config';
const Login = () => {
  const navigate = useNavigate()
  const[userLogin,{isLoading, isSuccess, data:LoginRes, isError}]=useUserLoginMutation()
  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Alert',
      description:
        'You have entered wrong data. Please try again.',
    });
  };
  const handleSubmit=async(data)=>{ 
      let result = await userLogin(data)
      console.log(result);
  }
  useEffect(()=>{
    if(isError)openNotificationWithIcon('error')
    // console.log(expiryDate);
  },[isError])
  useEffect(()=>{
    if(isSuccess && LoginRes?.data.userId!=""){

      localStorage.setItem('isLogin',JSON.stringify(LoginRes.data));
      let expiryDate = new Date().getTime()+5*60*60*1000
      localStorage.setItem('expiry',expiryDate)
      window.location.href = baseURL+'/dashboard'
    }
    else if(LoginRes?.data.userId==""){
      
      openNotificationWithIcon('error')
    }
  },[isSuccess])
  return (
    <React.Fragment>
      <div >
        {contextHolder}
        <div className="bg-overlay"></div>
        <div className="account-pages my-5 pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={4}>
                <Card>
                  <CardBody className="p-4">
                    <div>
                      <div className="text-center">
                        <Link to="/">
                          <img
                            src={logolight}
                            alt=""
                            height="100"
                            className="auth-logo"
                          />
                        </Link>
                      </div>
                      {/* <p className="mb-5 text-center">
                        Sign in to continue to Magic Paint.
                      </p> */}
                      <Form
                        layout='vertical'
                        className="form-horizontal"
                        onFinish={handleSubmit}
                      >
                        {/* {error ? <Alert color="danger"><div>{error}</div></Alert> : null} */}
                        <Row>
                          <Col md={12}>
                            <div className="mb-4">
                              <Form.Item name="loginid" label="Login ID" rules={[{ required:true,message:'Please enter login Id' }]}>
                                <Input type='text' placeholder='enter login id'/>
                              </Form.Item>
                            </div>
                            <div className="mb-4">
                            <Form.Item name="pass" label="Password" rules={[{ required:true,message:'Please enter password' }]}>
                                <Input type='password' placeholder='enter password' />
                              </Form.Item>
                            </div>

                            
                            <div className="d-grid mt-4">
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                            <div className="mt-4 text-center">

                            </div>

                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          {
            isLoading && 
            <Loader />
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login