import React from 'react'
import { Spinner } from 'reactstrap'

const Loader = () => {
    return (
        <>
            <div className="loader">
                <div className="bg-overlay" style={{height:'100vh',width:'100%',position:'fixed'}}></div>
                <div className='spinner'>
                <Spinner  color="warning">
                    Loading...
                </Spinner>
                <h4 className="text-warning">Please wait..</h4>
                </div>
            </div>
        </>
    )
}

export default Loader