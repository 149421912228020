import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { ActionRenderer, DateRenderer, ImageRenderer, StatusRenderer } from './Renderer'

const CustomTable = props => {
    const { column, dataSource,handleClick,xrow } = props
    const [colDef, setColDef] = useState([])
    useEffect(()=>{
      let colConfig = []
      column.map((item)=>{
        let row = {...item}
        if(row?.type == 'date'){
          row.render = DateRenderer
        }
        else if(row?.type == 'action'){
          row.render = ActionRenderer
        }
        else if(row?.type == 'image'){
          row.render = ImageRenderer
        }
        colConfig.push(row)
      })
      setColDef(colConfig)
    },[])

    const mergedColumns = column.map((col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          type: col.type,
          dataIndex: col.dataIndex,
          col: col,
          handleClick:handleClick
        }),
      };
    });
    return (
        <React.Fragment>
            <Table
                scroll={{ y: 400,x: xrow || 1500}}
                components={{
                  body:{
                    cell:cellRendered
                  }
                }}
                columns={mergedColumns}
                dataSource={dataSource}
                pagination
            />
        </React.Fragment>
    )
}

CustomTable.propTypes = {
    column: PropTypes.array.isRequired,
    dataSource: PropTypes.array.isRequired,
    rowSelection: PropTypes.func
}

const cellRendered=({record,dataIndex,col,handleClick,children,...restProps})=>{
  let childNode;
  if(col?.type==='action'){
    childNode = <ActionRenderer actionList={col.actionList} handleClick={handleClick} record={record}/>
  }
  if(col?.type==='image'){
    childNode = <ImageRenderer src={record.fileName} height={'60px'}/>
  }
  else if(col?.type==='date'){
    childNode =DateRenderer(record[dataIndex])
  }
  else if(col?.type==='status'){
    childNode = StatusRenderer(record[dataIndex])
  }
  return (
    <td {...restProps}>
      {childNode?childNode:children}
    </td>
  )
  
}

export default CustomTable