import { combineSlices } from '@reduxjs/toolkit';
import apiService from './apiService';

export const rootReducer = combineSlices(
    /* Dynamic Slices */
    {
        [apiService.reducerPath]: apiService.reducer
    }
)

