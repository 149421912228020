import endPoints from "../../config/apiList";
import {apiService as api} from "../apiService";

const addTagTypes=['login']
const schemeAPI = api.enhanceEndpoints({
    addTagTypes
})
.injectEndpoints({
    endpoints:(builder)=>({
        addUpdateScheme:builder.mutation({
            query: (queryArg) => ({
                url: endPoints.schemeAPI.AddUpdate,
                method: 'POST',
                data: queryArg,
                headers: {
                    'Content-Type':'application/json'
                }
            })
        }),
        getSchemeData:builder.mutation({
            query: (queryArg) => ({
                url: endPoints.schemeAPI.getSchemeData,
                method: 'POST',
                data: queryArg,
                headers: {
                    'Content-Type':'application/json'
                }
            })
        }),
        getSchemeList: (builder).query({
            query:(queryArg) => ({
                url: endPoints.schemeAPI.list,
                method: 'POST',
                data: queryArg
            })
        }),
    })
})

export const {
    useGetSchemeListQuery,
    useAddUpdateSchemeMutation,
    useGetSchemeDataMutation
} = schemeAPI
export default schemeAPI;