import endPoints from "../../config/apiList";
import {apiService as api} from "../apiService";

const addTagTypes=["device_list"];
const authAPI = api.enhanceEndpoints({
    addTagTypes
})
.injectEndpoints({
    endpoints:(builder)=>({
        userLogin:builder.mutation({
            query: (queryArg) => ({
                url: endPoints.login,
                method: 'POST',
                data: queryArg
            })
        }),
        overrideExisting: false
    })
})

export const {
    useUserLoginMutation
} = authAPI
export default authAPI;