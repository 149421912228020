import React, { useState, useEffect, useCallback } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import profile from "../../assets/images/profile.png"
// users
const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate()

  const [username, setusername] = useState("Admin");
  const getUserName = useCallback(()=>{
    if (localStorage.getItem("isLogin")) {
      let name = JSON.parse(localStorage.getItem('isLogin')).userName;

      setusername(name)
    }
  })
  useEffect(() => {
    getUserName()
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profile}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-2 text-capitalize">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/userprofile">
            {" "}
            <i className="ri-user-line align-middle me-2" />
            {("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen" >
            <i className="ri-lock-unlock-line align-middle me-2" />
            {("Change Password")}
          </DropdownItem>
          <DropdownItem tag="a" onClick={()=>{
            localStorage.clear();
            navigate('/login')
          }} >
          <i className="ri-shut-down-line align-middle me-2 text-danger" />
          <span>{("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};


export default ProfileMenu