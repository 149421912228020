import { Button, DatePicker, Form, Image, Input, message, Radio, Select, Space, Table, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import EditableTable from '../../components/table/EditableTable'
import { DeleteFilled, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { useCreateDealerMutation, useGetDealerDetailByGSTMutation } from '../../store/APIQuery/dealerAPI'
import { ConstitutionofBusiness, state } from '../../utils/jsonData/json'
import FileBase64 from 'react-file-base64';
import Popup from '../../components/popup/popup'
import { useNavigate } from 'react-router-dom'
import { customRegExp, getUserId } from '../../helpers/helper'
import ImageWithPreview from '../../components/Image/ImageWithPreview'
import DocInput from '../../components/docInput/DocInput'
import moment from 'moment'
import validationRules from '../../helpers/validationRules'

const DealerRegForm = (props) => {
    const { setUpLoader } = props;
    const [form] = Form.useForm();
    const [getDealerDetailByGST, { isLoading, isSuccess, data: dealerData = {} }] = useGetDealerDetailByGSTMutation()
    const [{ showPopup, status, msg }, togglePopup] = useState({ showPopup: false, status: false, msg: "" })
    const [createDealer, { isLoading: isReqProcessing, isSuccess: ReqProcessed, isError, data: dealerCreateRes }] = useCreateDealerMutation()
    const [fileList, setFileList] = useState({ DealerPhoto: "", AadharCardPhoto: "", cheaquePhoto: "" })
    const {pincodeValidation, panValidation, gstValidation, TextValidation, adharValidation, mobileValidation, numberWithCommaValidation, numberValidation, AddressValidation} = validationRules
    const [isGSTAvailable, toggleGSTAvailable] = useState(null)
    const [showForm, toggleForm] = useState(false)
    const [DealerInitialData, setDealerInitialData] = useState({})
    // Director list
    const [rowData, setRowData] = useState([{
        key: 1,
        Dir: "",
        PanNo: "",
        Adharno: "",
        Mobileno: "",
        DOB: "",
        DOA: ""
    }])
    const [companyRowData, setCompanyRowData] = useState([{
        key: 1,
        dealingwith: "",
        turnover: "",
        cMobileno: ""
    }])
    const [referenceRowData, setReferenceRowData] = useState([{
        key: 1,
        Name: "",
        MobileNo: "",
    }])
    const [turnoverRowData, setTurnoverRowData] = useState([{
        key: 1,
        years: "",
        turnovers: "",
    }])

    const addNewRow = (type) => {
        if (type === 'director') {
            let count = rowData.length;
            let lastData = rowData[count - 1]
            let data = [...rowData]
            data.push({
                key: lastData.key + 1,
                Dir: "",
                PanNo: "",
                Adharno: "",
                Mobileno: "",
                DOB: "",
                DOA: ""
            })
            setRowData(data)
        }
        else if (type === 'company') {
            let count = companyRowData.length;
            let lastData = companyRowData[count - 1]
            let data = [...companyRowData]
            data.push({
                key: lastData.key + 1,
                dealingwith: "",
                turnover: "",
                cMobileno: ""
            })
            setCompanyRowData(data)
        }
        else if (type === 'turnover') {
            let count = turnoverRowData.length;
            let lastData = turnoverRowData[count - 1]
            let data = [...turnoverRowData]
            data.push({
                key: lastData.key + 1,
                years: "",
                turnovers: "",
            })
            setTurnoverRowData(data)
        }
        else {
            let count = referenceRowData.length;
            let lastData = referenceRowData[count - 1]
            let data = [...referenceRowData]
            data.push({
                key: lastData.key + 1,
                Name: "",
                MobileNo: "",
            })
            setReferenceRowData(data)
        }
    }

    const removeItem = (key, type) => {
        if (type === 'director') {
            const filteredPeople = rowData.filter((item) => item.key !== key);
            setRowData(filteredPeople)
        }
        else if (type === 'company') {
            const filteredPeople = companyRowData.filter((item) => item.key !== key);
            setCompanyRowData(filteredPeople)
        }
        else if (type === 'turnover') {
            const filteredPeople = turnoverRowData.filter((item) => item.key !== key);
            setTurnoverRowData(filteredPeople)
        }
        else {
            const filteredPeople = referenceRowData.filter((item) => item.key !== key);
            setReferenceRowData(filteredPeople)
        }
    }
    const colDef = [
        {
            title: "Name of Dir/Prop.",
            dataIndex: "Dir",
            editable: true,
            isRequired: true,
            rules:[ {required:true, message: "Please enter a name"}, ...TextValidation],
            inputType: 'text'
        },
        {
            title: "Pan No.",
            dataIndex: "PanNo",
            editable: true,
            rules:[ {required:true, message: "Please enter a PAN"}, ...panValidation],
            inputType: 'text'
        },
        {
            title: "Adhar Card No",
            dataIndex: "Adharno",
            editable: true,
            rules:[...adharValidation],
            inputType: 'text'
        },
        {
            title: "Mobile No",
            dataIndex: "Mobileno",
            editable: true,  
            rules:[...mobileValidation],
            inputType: 'text'
        },
        {
            title: "Date Of Birth",
            dataIndex: "DOB",
            editable: true,
            isRequired: false,
            inputType: 'date'
        },
        {
            title: "Date Of Anniversary",
            dataIndex: "DOA",
            editable: true,
            isRequired: false,
            inputType: 'date'
        },
        {
            title: "#",
            dataIndex: "action",
            editable: false,
            render: (_, record) => {
                if (record.key > 1) {
                    return <span className='addIcon text-danger' onClick={() => removeItem(record.key, 'director')}><DeleteFilled /></span>
                }
                return <span className='addIcon' onClick={() => addNewRow('director')}><PlusOutlined /></span>
            }
        }
    ]

    const companyColDef = [
        {
            title: "Name of Company",
            dataIndex: "dealingwith",
            editable: true,  
            rules:[...TextValidation],
            inputType: 'text'
        },
        {
            title: "Turnover(In Lacs)",
            dataIndex: "turnover",   
            rules:[...numberValidation],
            editable: true,
            inputType: 'text'
        },
        {
            title: "Contact",
            dataIndex: "cMobileno",  
            rules:[...mobileValidation],
            editable: true,
            inputType: 'text'
        },
        {
            title: "#",
            dataIndex: "action",
            editable: false,
            render: (_, record) => {
                if (record.key > 1) {
                    return <span className='addIcon text-danger' onClick={() => removeItem(record.key, 'company')}><DeleteFilled /></span>
                }
                return <span className='addIcon' onClick={() => addNewRow('company')}><PlusOutlined /></span>
            }
        }
    ]
    const referenceColDef = [
        {
            title: "Reference Name",
            dataIndex: "Name",
            rules:[...TextValidation],
            editable: true,
            inputType: 'text'
        },
        {
            title: "Contact No.",
            dataIndex: "MobileNo",
            rules:[...mobileValidation],  
            editable: true,
            inputType: 'text'
        },
        {
            title: "#",
            dataIndex: "action",
            editable: false,
            render: (_, record) => {
                if (record.key > 1) {
                    return <span className='addIcon text-danger' onClick={() => removeItem(record.key, 'reference')}><DeleteFilled /></span>
                }
                return <span className='addIcon' onClick={() => addNewRow('reference')}><PlusOutlined /></span>
            }
        }
    ]
    const turnoverColDef = [
        {
            title: "Year",
            dataIndex: "years",
            editable: true,
            inputType: 'text'
        },
        {
            title: "TurnOver(in Lacs)",
            dataIndex: "turnovers",
            editable: true,
            rules:[...numberValidation],
            inputType: 'text'
        },
        {
            title: "#",
            dataIndex: "action",
            editable: false,
            render: (_, record) => {
                if (record.key > 1) {
                    return <span className='addIcon text-danger' onClick={() => removeItem(record.key, 'turnover')}><DeleteFilled /></span>
                }
                return <span className='addIcon' onClick={() => addNewRow('turnover')}><PlusOutlined /></span>
            }
        }
    ]

    useEffect(() => {
        setUpLoader((isLoading || isReqProcessing))
    }, [isLoading, isReqProcessing])

    useEffect(() => {

    }, [])

    const [GSTno, setGSTno] = useState("")

    const handleGSTChange = (e) => {
        setGSTno(e.target.value)
    }

    const searchDealerByGST = () => {
        const userId = getUserId()
        // console.log(form)
        getDealerDetailByGST({ gstNo: GSTno, UserId:userId })
    }

    // set dealer data in form
    useEffect(() => {
        if (dealerData.success == "true" && dealerData.status == '200') {
            if (dealerData.data.status != "A") {
                let { perEmailid, pstates, transactionNo, dateofestiblished, transactionAmount, transactionDate, payMode } = dealerData.data
                // console.log(transactionDate)
                setDealerInitialData(dealerData)
                form.setFieldsValue({
                    ...dealerData.data,
                    PerEmailid: perEmailid,
                    state: pstates,
                    TransactionNo: transactionNo,
                    PayMode: payMode,
                    TransAmount: transactionAmount,
                    TransDate: transactionDate?moment(transactionDate):false,
                    dateofestiblished: dateofestiblished?moment(dateofestiblished):false
                })

                if (dealerData.data) {
                    let { obj: dealerObj, objT: companyObj, objAT: anualTurnObj, objRefNo: referenceObj } = dealerData.data
                    if (dealerObj && dealerObj.length > 0) {
                        let newDealerObj = []
                        dealerObj.map((item, key) => {
                            newDealerObj.push({
                                key: key + 1,
                                Dir: item.dir,
                                PanNo: item.panNo,
                                Adharno: item.adharno,
                                Mobileno: item.mobileno,
                                DOB: item.dob.indexOf('1900')>-1?"":moment(item.dob),
                                DOA: item.doa.indexOf('1900')>-1?"":moment(item.doa),
                            })
                            setTimeout(() => {
                                form.setFieldsValue({
                                    [`Dir${key + 1}`]: item.dir,
                                    [`PanNo${key + 1}`]: item.panNo,
                                })
                            }, 1000)
                        })

                        setRowData(newDealerObj)
                    }
                    else {
                        setRowData([
                            {
                                key: 1,
                                Dir: "",
                                PanNo: "",
                                Adharno: "",
                                Mobileno: "",
                                DOB: "",
                                DOA: ""
                            }
                        ])
                    }
                    // prepare company table list
                    if (companyObj && companyObj.length > 0) {
                        let newCompanyObj = []
                        companyObj.map((item, key) => {
                            newCompanyObj.push({
                                key: key + 1,
                                dealingwith: item.dealingwith,
                                turnover: item.turnover,
                                cMobileno: item.mobileno
                            })
                        })
                        setCompanyRowData(newCompanyObj)
                    }
                    else {
                        setCompanyRowData([{
                            key: 1,
                            dealingwith: "",
                            turnover: "",
                            cMobileno: ""
                        }])
                    }

                    // prepare reference table list
                    if (referenceObj && referenceObj.length > 0) {
                        let newRefObj = []
                        referenceObj.map((item, key) => {
                            newRefObj.push({
                                key: key + 1,
                                Name: item.name,
                                MobileNo: item.mobileNo
                            })
                        })
                        setReferenceRowData(newRefObj)
                    }
                    else {
                        setReferenceRowData([{
                            key: 1,
                            Name: "",
                            MobileNo: "",
                        }])
                    }

                    // prepare annual turnover table list
                    if (anualTurnObj && anualTurnObj.length > 0) {
                        let newATObj = []
                        anualTurnObj.map((item, key) => {
                            newATObj.push({
                                key: key + 1,
                                years: item.years,
                                turnovers: item.turnovers
                            })
                        })
                        setTurnoverRowData(newATObj)
                    }
                    else {
                        setTurnoverRowData([{
                            key: 1,
                            years: "",
                            turnovers: "",
                        }])
                    }
                    toggleForm(true)
                }
            }
            else {
                togglePopup({
                    showPopup: true,
                    status: false,
                    msg: "This GSTN is already registered and approved. Please use different GSTN."
                })
            }
        }
        else if (dealerData.status == '201') {
            togglePopup({
                showPopup: true,
                status: false,
                msg: "Pleaes enter valid GSTN"
            })
        }
        else if (dealerData.status == '202') {
            togglePopup({
                showPopup: true,
                status: false,
                msg: "Some thing went wrong, Please try again later!"
            })
        }
        else if (dealerData.status == '203') {
            togglePopup({
                showPopup: true,
                status: false,
                msg: "This GSTN is already registered by other user!"
            })
        }
    }, [dealerData])

    const setDataInRows = (rowType, name, value, key) => {
        // console.log(rowType, name, value, key)
        let dataArr = [];
        switch (rowType) {
            case "director":
                dataArr = [...rowData]
                dataArr.map((item) => {
                    if (item.key === key) {
                        item[name] = value
                    }
                })
                setRowData(dataArr);
                break;
            case "company":
                dataArr = [...companyRowData]
                dataArr.map((item) => {
                    if (item.key === key) {
                        item[name] = value
                    }
                })
                setCompanyRowData(dataArr)
                break;
            case "turnover":
                dataArr = [...turnoverRowData]
                dataArr.map((item) => {
                    if (item.key === key) {
                        item[name] = value
                    }
                })
                setTurnoverRowData(dataArr)
                break;
            case "reference":
                dataArr = [...referenceRowData]
                dataArr.map((item) => {
                    if (item.key === key) {
                        item[name] = value
                    }
                })
                setReferenceRowData(dataArr)
                break;
        }
    }

    const handleSubmit = (data) => {
        const formData = prepareFormData(data)
        // console.log(formData)
        createDealer(formData)
    }

    const prepareFormData = (data) => {
        const { dealerName, address, dateofestiblished, bussinesstype, gstno, pincode, state, firmPANNo, bankname, bankaccountno, bankifsccode, godownspaceavail, godownAddress, currStrainth, secrurityDeposit, contactperson, perMobileno, PerEmailid, dirpincode, resAddress, mobileNo, telephoneno, PayMode, TransDate, TransAmount, TransactionNo } = data;
        let objDirector = rowData.filter(item => item.name !== "").map((item) => { return { ...item, gstno: gstno } });
        let objT = companyRowData.filter(item => item.dealingwith !== "").map((item) => { return { ...item, gstno: gstno, Mobileno: item.cMobileno } });
        let objAT = turnoverRowData.filter(item => item.years !== "").map((item) => { return { ...item, gstno: gstno } });
        let objRef = referenceRowData.filter(item => item.Name !== "").map((item) => { return { ...item, GstNO: gstno } });
        const userId = getUserId()
        // const {} = files
        const Registeredby = userId
        let params = {
            UpdateMode: DealerInitialData?.exists == 'Y' ? "U" : "I",
            RefNo1: DealerInitialData?.data?.dealerRegReqNo,
            DealerName: dealerName,
            Address: address,
            MobileNo: mobileNo,
            Dateofestiblished: dateofestiblished?dateofestiblished.format("DD-MMM-YYYY"):"",
            bussinesstype:bussinesstype,
            gstno:gstno,
            pincode:pincode,
            pstates: state,
            FirmPANNo: firmPANNo,
            Bankname: bankname,
            Bankaccountno: bankaccountno,
            bankifsccode:bankifsccode,
            Godownspaceavail: godownspaceavail,
            GodownAddress: godownAddress,
            CurrStrainth: currStrainth,
            SecrurityDeposit: secrurityDeposit,
            Contactperson: contactperson,
            perMobileno:perMobileno,
            PerEmailid:PerEmailid,
            DealerPhoto: fileList.DealerPhoto,
            cheaquePhoto: fileList.cheaquePhoto,
            AadharCardPhoto: fileList.AadharCardPhoto,
            Telephoneno: telephoneno,
            DirPincode: dirpincode,
            workAddress: resAddress,
            objDirector:objDirector,
            objT:objT,
            objAT:objAT,
            objRef:objRef,
            Registeredby:Registeredby,
            PayMode:PayMode,
            TransDate:TransDate?TransDate.format("DD-MMM-YYYY"):"",
            TransAmount:TransAmount,
            TransactionNo:TransactionNo
        }
        for (const property in params) {
            if(params[property]===undefined) {
                params[property]=["CurrStrainth","SecrurityDeposit","TransAmount"].indexOf(property)>-1?0:""
            }
        }
        return params;

    }

    const handleChangeFile = (type, file) => {
        console.log(file)
        setFileList({
            ...fileList,
            [type]: file.base64
        })
    }
    const navigate = useNavigate()
    
    useEffect(() => {
        if (dealerCreateRes?.success == "true" && (dealerCreateRes?.message=="Registered" || dealerCreateRes?.message=="Data Updated")) {
            togglePopup({
                showPopup: true,
                status: true,
                msg: "Your data save successfully"
            });
        }
        else if (dealerCreateRes?.success == "true"  && dealerCreateRes?.message=="Dealer already exists") {
            // togglePopup(true)
            togglePopup({
                showPopup: true,
                status: false,
                msg: "Dealer name or gst number already exists."
            });
        }
        else if (dealerCreateRes?.success == "fail") {
            // togglePopup(true)
            togglePopup({
                showPopup: true,
                status: false,
                msg: "Some thing went wrong, Please try again later."
            });
        }

    }, [dealerCreateRes])
    const closePopup = () => {
        if (status) {
            navigate('/dealer-application', { state: { refetch: true } })
        }
        togglePopup({
            status: false,
            showPopup: false
        })
    }

    const radioOnChange = (e) => {
        const value = e.target.value
        toggleGSTAvailable(value)
        toggleForm(!value)
    }
    
    return (
        <React.Fragment>
            <div className='mb-2 d-flex'>
                <p className='text-dark mb-1' style={{ fontSize: '15px', marginRight: '10px' }}><b>Do you have GSTN?</b></p>
                <Radio.Group onChange={radioOnChange} value={isGSTAvailable} disabled={isGSTAvailable && showForm}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </div>
            {
                isGSTAvailable &&
                <Form layout='vertical' >
                    <Row>
                        <Col sm={4}>
                            <Space style={{ display: 'block' }}>
                                <Form.Item name="gstNo" label="GSTN" rules={[{ pattern: customRegExp.alphanum(), message: "Enter valid GST" }]}>
                                    <Space.Compact style={{ width: '100%' }}>
                                        <Input placeholder='enter GST number' value={GSTno} onChange={handleGSTChange} className='text-uppercase' maxLength={15} minLength={15} />
                                        {/* <DocInput fieldType="gst" onChange={handleGSTChange} /> */}
                                        <Button type="primary" onClick={searchDealerByGST} disabled={GSTno.length != 15}>Verify</Button>
                                    </Space.Compact>
                                </Form.Item>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            }
            {
                showForm &&
                <Form onFinish={handleSubmit} layout='vertical' className='regForm' form={form} scrollToFirstError={true} >
                    <>
                        <Row>
                            <Col sm={4}>
                                <Form.Item name={`dealerName`} label="Legal Name of Bussiness" rules={[{ required: true, message:"Please enter Bussiness name"}, ...TextValidation]} >
                                    <Input placeholder='Eg. ABC Company' />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`address`} label="Registered Address" rules={[{ required: true, message:"Please enter Registered Address"}, ...AddressValidation]}>
                                    <Input placeholder='Eg. New Delhi' />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`state`} label="State" rules={[{ required: true }]}>
                                    <Select placeholder="Select State" options={state} showSearch ></Select>
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`pincode`} label="Pincode" rules={[{ required: true, message: "Please enter Pincode" }, ...pincodeValidation]}>
                                    <Input placeholder='Eg. XXXXXX' maxLength={6}/>
                                </Form.Item>
                            </Col>
                            <Col sm={4}>

                                <Form.Item name={`mobileNo`} label="Registered Mobile No" rules={[{ required: true, message:"Please enter mobile number"},...mobileValidation]}>
                                    <Input placeholder='Eg. XXXXXXXXXX' maxLength={10} />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`resAddress`} label="Work Address" rules={[{ required: true, message:"Please enter work address"},...AddressValidation]}>
                                    <Input placeholder='Eg. New Delhi' />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`dirpincode`} label="Pincode" rules={[...pincodeValidation]}>
                                    <Input placeholder='Eg. XXXXXX' maxLength={6}/>
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`bussinesstype`} label="Constitution of Business" rules={[{ required: false }]}>
                                    <Select placeholder="Constitution of Business" options={ConstitutionofBusiness}></Select>
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`gstno`} label="GST No." rules={[...gstValidation]}>
                                    <Input placeholder='enter GST ' className='text-uppercase' maxLength={15} disabled={isGSTAvailable=="yes"?true:false} />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`contactperson`} label="Contact Person" rules={[...TextValidation]}>
                                    <Input placeholder='enter Contact Person' />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`perMobileno`} label="Person Mobile no" rules={[...mobileValidation]}>
                                    <Input placeholder='Enter Mobile No' maxLength={10}/>
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`PerEmailid`} label="Email" rules={[{ required: false, type: "email", message: "The input is not valid E-mail!", }]}>
                                    <Input placeholder='enter email' />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`dateofestiblished`} label="Year Of Establishment">
                                    <DatePicker  style={{ width: '100%' }}  />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`firmPANNo`} label="Pan No of Firm" rules={[...panValidation]}>
                                    <Input type="text" placeholder='enter Pan No of Firm' maxLength={10} minLength={10} className='text-uppercase' />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`telephoneno`} label="Telephone No." rules={[{pattern:customRegExp.numberWithComma(), message: "Enter valid telephone no"}]}>
                                    <Input type="text" placeholder='enter Telephone No.' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <h5 className="subtitle">Company's Stakeholders:</h5>
                                <EditableTable
                                    columns={colDef}
                                    rowData={rowData}
                                    onChange={setDataInRows}
                                    tableType={`director`}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Item name={`bankname`} label="Bank Name" rules={[...TextValidation]}>
                                    <Input placeholder='enter bank name' />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`bankaccountno`} label="Account No." rules={[...numberWithCommaValidation]}>
                                    <Input placeholder='enter Account No' />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item name={`bankifsccode`} label="IFSC Code"  rules={[{pattern:customRegExp.alphanum(), message:"Enter valid IFSC Code"}]}>
                                    <Input placeholder='enter IFSC Code' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <h5 className="subtitle">Company Dealing With:</h5>
                            </Col>
                            <Col lg={12}>
                                <EditableTable
                                    columns={companyColDef}
                                    rowData={companyRowData}
                                    onChange={setDataInRows}
                                    tableType={`company`}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <h5 className="subtitle">Reference With Contact No.:</h5>
                                <EditableTable
                                    columns={referenceColDef}
                                    rowData={referenceRowData}
                                    onChange={setDataInRows}
                                    tableType={`reference`}
                                />
                            </Col>
                            <Col sm={6}>
                                <h5 className="subtitle">Anual Turnover(Last 3 Years):</h5>
                                <EditableTable
                                    columns={turnoverColDef}
                                    rowData={turnoverRowData}
                                    onChange={setDataInRows}
                                    tableType={`turnover`}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <Form.Item name={`godownspaceavail`} label="Godown Space Available" >
                                    <Select>
                                        <Select.Option value="Owned">Owned</Select.Option>
                                        <Select.Option value="Rent">Rent</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={6}>
                                <Form.Item name={`godownAddress`} label="Godown Address" >
                                    <Input placeholder='enter Godown Address' />
                                </Form.Item>
                            </Col>
                            <Col sm={3}>
                                <Form.Item name={`currStrainth`} label="Currently Manpower Strength" >
                                    <Input placeholder='enter Currently Manpower Strength' rules={[{pattern: customRegExp.number(), message: "Please enter number only" }]}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <h5 className="subtitle">Security Deposit Details:</h5>
                            </Col>
                            <Col sm={3}>
                                <Form.Item name={`PayMode`} label="Security Deposit Mode" >
                                    <Select placeholder="Select payment method">
                                        <Select.Option value="NEFT">NEFT</Select.Option>
                                        <Select.Option value="RTGS">RTGS</Select.Option>
                                        <Select.Option value="DRAFT">DRAFT</Select.Option>
                                        <Select.Option value="CHEQUE">CHEQUE</Select.Option>
                                        <Select.Option value="CASH">CASH</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={3}>
                                <Form.Item name={`TransactionNo`} label="Transaction/Draft/Cheque No" rules={[{pattern: customRegExp.number(), message: "Please enter number only" }]}>
                                    <Input placeholder='Transaction id' />
                                </Form.Item>
                            </Col>
                            <Col sm={3}>
                                <Form.Item name={`TransAmount`} label="Secrurity Deposit" rules={[{pattern: customRegExp.number(), message: "Please enter number only" }]}>
                                    <Input placeholder='enter secrurity Deposit' />
                                </Form.Item>
                            </Col>
                            <Col sm={3}>
                                <Form.Item name={`TransDate`} label="Secrurity Deposit Date" >
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}><h5 className="subtitle">File Uploads:</h5></Col>
                            <Col sm={4}>
                                <label>Dealer's Photo</label>
                                {
                                    fileList.DealerPhoto &&
                                    <div className="text-center mb-3">
                                        <ImageWithPreview src={fileList.DealerPhoto} />
                                    </div>
                                }
                                <FileBase64
                                    multiple={false}
                                    ac
                                    onDone={(file) => handleChangeFile("DealerPhoto", file)}
                                />
                            </Col>
                            <Col sm={4}>
                                <label>Cheque Photo</label>
                                {
                                    fileList.cheaquePhoto &&
                                    <div className="text-center mb-3">
                                        <ImageWithPreview src={fileList.cheaquePhoto} />
                                    </div>
                                }
                                <FileBase64
                                    multiple={false}
                                    onDone={(file) => handleChangeFile("cheaquePhoto", file)}
                                />
                            </Col>
                            <Col sm={4}>
                                <label>Dealer's Adhaar Photo</label>
                                {
                                    fileList.AadharCardPhoto &&
                                    <div className="text-center mb-3">
                                        <ImageWithPreview src={fileList.AadharCardPhoto} />
                                    </div>
                                }
                                <FileBase64
                                    multiple={false}
                                    onDone={(file) => handleChangeFile("AadharCardPhoto", file)}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Col sm={12} className="text-center">
                                <Button htmlType='submit' type="primary">Submit</Button>&nbsp;&nbsp;
                                {/* <Button type="primary" className='bg-success'>Hold</Button>&nbsp;&nbsp; */}
                                <Button type="primary" danger>Cancel</Button>
                            </Col>
                        </Row>
                    </>
                </Form>
            }
            <Popup
                isOpen={showPopup}
                isSuccess={status}
                msg={msg}
                closePopup={closePopup}
                btnText='Close'
            />
        </React.Fragment>
    )
}

export default DealerRegForm