import { DatePicker, Form, Input, Table } from 'antd';
import React from 'react'
import dayjs from 'dayjs';
import * as moment from 'moment';
import FileBase64 from 'react-file-base64';
const EditableTable = (props) => {
    const {columns,rowData:data,onChange,tableType,isPreview=false}=props
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            ...col,
            record,
            editing: true,
            tableType:tableType,
            onChange:onChange,
            isPreview:isPreview
          }),
        };
      });
    return (
        <>
            <Table
                scroll={{ x: "max-content" }} 
                components={{
                body: {
                    cell: EditableCell,
                },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
            />
        </>
    )
}


const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    tableType,
    children,
    onChange,
    isPreview,
    rules,
    ...restProps
  }) => {
    let inputNode;
    if(inputType==='text' || inputType==='number'){
        inputNode=<Input type={inputType} onChange={(e)=>onChange(tableType,dataIndex,e.target.value,record.key)} disabled={isPreview}/>
    }
    else if(inputType==='fileUpload'){
      inputNode=<FileBase64 multiple={false} onDone={(file) => onChange(tableType,dataIndex, file, record.key)}
  />
    }
    else if(inputType==='date'){
        inputNode=<DatePicker onChange={(date,dateString)=>onChange(tableType,dataIndex,dateString,record.key)} defaultValue={(dataIndex && record[dataIndex]!="")?dayjs(record[dataIndex],"YYYY-MM-DD"):false} disabled={isPreview} disabledDate={(current) => {
          return moment().add((dataIndex=='DOB')?-18:0, 'year')  <= current;
          }} />
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item 
            initialValue={dataIndex?record[dataIndex]:false}
            name={dataIndex+record?.key}
            style={{
              margin: 0,
            }}
            rules={rules?[...rules]:[]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

export default EditableTable