import { Navigate, Route } from "react-router-dom";

const AuthProtected = (props) => {
  // eslint-disable-next-line react/prop-types
  const {children}=props
  const user = localStorage.getItem('isLogin')
  /*
    redirect is un-auth access protected routes via url
    */

  if (!user) {
    return (
      <Navigate to={{ pathname: "/login"}} />
    );
  }

  return <>{children}</>
};
const CheckAuth = (props) => {
  // eslint-disable-next-line react/prop-types
  const {children}=props
  const user = localStorage.getItem('isLogin')
  /*
    redirect is un-auth access protected routes via url
    */

  if (user) {
    return (
      <Navigate to={{ pathname: "/dashboard"}} />
    );
  }

  return <>{children}</>
};

// eslint-disable-next-line react/prop-types
const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute, CheckAuth };
