import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, NavItem, Row } from 'reactstrap'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import CustomTable from '../../components/table/CustomTable'
import DealerHeader, { data } from '../../gridHeader/DealerHeader'
import { useGetDealerListQuery } from '../../store/APIQuery/dealerAPI'
import Loader from '../../components/loader/Loader'
import Popup from '../../components/popup/popup'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserId } from '../../helpers/helper'
import { Radio } from 'antd'

const DealerList = () => {
    const {pathname} = useLocation()
    const [dealerData, setDealerList] = useState([])
    const [dealerStatus, setdealerStatus] = useState("All")
    const { isLoading, data, isSuccess, refetch } = useGetDealerListQuery({ registeredBy: getUserId() })
    const [{showPopup,success,msg}, togglePopup] = useState({success: false,msg:"",showPopup:false})
    const navigate = useNavigate()
    const [colDef, setCol] = useState([])

    useEffect(()=>{
        let column = [... DealerHeader.gridColumn(),
            {
                title: "Approve Date",
                dataIndex: "approvedDate",
                type:'date',
                width:180
            },
            {
                title: "Approve By",
                dataIndex: "approvedby",
                width:180
            }
        ]
        setCol(column)
        refetch()
    },[])

    useEffect(() => {
        if (data?.success) {            
            setDealerList(data.data?.filter(item=>pathname=='/dealer-list'?item.status!="":item.status==""))
        }
    }, [data,pathname])

    const handleClick = (type, data) => {
        // console.log(type,data)
        if(type=='Edit' && data.status=="R"){
            togglePopup({
                showPopup:true,
                success:false,
                msg:`This dealer already rejected, You are not allowed to update data.`
            })
        }
        else if((type=='Edit' && data.status=="A") || (type=='view')){
            navigate('/dealer-operation',{state:{data:data.reqNo,type:type}})
        }
        // navigate('/dealer-operation',{state:{data:data.reqNo,type:type}})
    }

    const applyFilter=(e)=>{
        setdealerStatus(e.target.value);
        if(e.target.value=='All'){
            setDealerList(data.data?.filter(item=>item.status!=""))
        }
        else{
            setDealerList(data.data?.filter(item=>item.status==e.target.value))
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Dealer" breadcrumbItem={pathname=='/dealer-list'?"Dealer's List":"Dealer Application"} />
                    <Row className="mb-4">
                        <Col xl={12}>
                            <Card className="h-100 b-0">
                                <CardBody>
                                    <div className="d-flex mb-2">
                                        <label >Dealer By Status:</label> &nbsp;&nbsp;
                                        <Radio.Group name='status' value={dealerStatus} onChange={applyFilter}>
                                            <Radio value={'All'}>All</Radio>
                                            <Radio value={'A'}>Approve</Radio>
                                            <Radio value={'R'}>Rejected</Radio>
                                        </Radio.Group>
                                    </div>
                                    <CustomTable
                                        column={colDef}
                                        dataSource={dealerData}
                                        handleClick={handleClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {
                    isLoading &&
                    <Loader />
                }

                <Popup
                    isOpen={showPopup}
                    isSuccess={success}
                    msg={msg}
                    closePopup={() => togglePopup(false)}
                    btnText='Close'
                />

            </div>
        </React.Fragment>
    )
}

export default DealerList