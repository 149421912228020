const DealerHeader={
    PageName:'Verify Dealer Application',
    pagination:true,
    horizontalScroll:'',
    fixHeader:true,
    gridColumn:()=>[
        {
            title:'Action',
            dataIndex:'action',
            type:'action',
            actionList:[
                {title:'Edit',key:'edit'},
                {title:'View',key:'view'},
                {title:'Delete',key:'delete'},
            ],
            width:80,
            fixed: 'left',
        },
        {
            title: "Request No",
            dataIndex: "reqNo",
            width:140
        },
        {
            title: "Dealer Code",
            dataIndex: "dealerCode",
            width:140
        },
        {
            title: "GST No",
            dataIndex: "gstNo",
            width:140
        },
        {
            title: "Name of Bussiness",
            dataIndex: "bussinessname",
            width:200
        },
        {
            title: "Type of Bussiness",
            dataIndex: "typeofbussiness",
            width:150
        },
        {
            title: "Registered Mobile",
            dataIndex: "registeredMobileno",
            width:150
        },
        {
            title: "Status",
            dataIndex: "appStatus",
            type:'status',
            width:100
        },
        {
            title: "PAN of Firm",
            dataIndex: "firmpan",
            width:120
        },        
        {
            title: "State",
            dataIndex: "state",
            width:120
        },
        {
            title: "Requested By",
            dataIndex: "registeredby",
            width:120
        },
        {
            title: "Request Date",
            dataIndex: "registereddate",
            type:'date',
            width:180
        }
    ]
}

export const data=[
    {
        GSTNo:"24AFNPC6785L1ZX",
        NameofBusiness:"PAINTS AND COATINGS",
        RegisteredMobile:"9716334491",
        TypeofBussiness:"Proprietorship",
        ContactPerson:"fadsf",
        EmailID:"abce@gmail.com",
        PAN:"IUOSADIFSF",
        City:"Patna",
        State:"Bihar",
        RegDate:"03-02-2016",
        RegisteredBy:"Santosh",
    },
    {
        GSTNo:"24AFNPC6785L1ZX",
        NameofBusiness:"PAINTS AND COATINGS",
        RegisteredMobile:"9716334491",
        TypeofBussiness:"Proprietorship",
        ContactPerson:"fadsf",
        EmailID:"abce@gmail.com",
        PAN:"IUOSADIFSF",
        City:"Patna",
        State:"Bihar",
        RegDate:"03-02-2016",
        RegisteredBy:"Santosh",
    },
    {
        GSTNo:"24AFNPC6785L1ZX",
        NameofBusiness:"PAINTS AND COATINGS",
        RegisteredMobile:"9716334491",
        TypeofBussiness:"Proprietorship",
        ContactPerson:"fadsf",
        EmailID:"abce@gmail.com",
        PAN:"IUOSADIFSF",
        City:"Patna",
        State:"Bihar",
        RegDate:"03-02-2016",
        RegisteredBy:"Santosh",
    },
    {
        GSTNo:"24AFNPC6785L1ZX",
        NameofBusiness:"PAINTS AND COATINGS",
        RegisteredMobile:"9716334491",
        TypeofBussiness:"Proprietorship",
        ContactPerson:"fadsf",
        EmailID:"abce@gmail.com",
        PAN:"IUOSADIFSF",
        City:"Patna",
        State:"Bihar",
        RegDate:"03-02-2016",
        RegisteredBy:"Santosh",
    },
    {
        GSTNo:"24AFNPC6785L1ZX",
        NameofBusiness:"PAINTS AND COATINGS",
        RegisteredMobile:"9716334491",
        TypeofBussiness:"Proprietorship",
        ContactPerson:"fadsf",
        EmailID:"abce@gmail.com",
        PAN:"IUOSADIFSF",
        City:"Patna",
        State:"Bihar",
        RegDate:"03-02-2016",
        RegisteredBy:"Santosh",
    },
    {
        GSTNo:"24AFNPC6785L1ZX",
        NameofBusiness:"PAINTS AND COATINGS",
        RegisteredMobile:"9716334491",
        TypeofBussiness:"Proprietorship",
        ContactPerson:"fadsf",
        EmailID:"abce@gmail.com",
        PAN:"IUOSADIFSF",
        City:"Patna",
        State:"Bihar",
        RegDate:"03-02-2016",
        RegisteredBy:"Santosh",
    },
    {
        GSTNo:"24AFNPC6785L1ZX",
        NameofBusiness:"PAINTS AND COATINGS",
        RegisteredMobile:"9716334491",
        TypeofBussiness:"Proprietorship",
        ContactPerson:"fadsf",
        EmailID:"abce@gmail.com",
        PAN:"IUOSADIFSF",
        City:"Patna",
        State:"Bihar",
        RegDate:"03-02-2016",
        RegisteredBy:"Santosh",
    }
]

export default DealerHeader;