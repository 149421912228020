import React from 'react'
import Header from './VerticalLayout/Header'
import Sidebar from './VerticalLayout/Sidebar'
import Footer from './VerticalLayout/Footer'
import withRouter from '../components/common/withRouter'

const Layout = (props) => {
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar
          theme={`dark`}
          type={`dark`}
          isMobile={false}
        />
        <div className="main-content">{props.children}</div>
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  )
}

export default withRouter(Layout)