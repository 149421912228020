import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import SchemeDetailHeader from '../../gridHeader/SchemeDetailHeader'
import CustomTable from '../../components/table/CustomTable'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useGetSchemeDetailListQuery } from '../../store/APIQuery/schemeDetailAPI'
import Loader from '../../components/loader/Loader'
import { useGetSchemeListQuery } from '../../store/APIQuery/schemeAPI'

const SchemeDetail = () => {
    const [schemeData, setSchemeList] = useState([])
    const { isLoading, isSuccess, data, refetch } = useGetSchemeDetailListQuery()
    const { data: schemeList, isLoading:schemeLoading} = useGetSchemeListQuery()
    const navigate = useNavigate()
    const { state } = useLocation()
    useEffect(() => {
        if (data?.status == "200" && data?.success == 'true') {
            setSchemeList(data.data)
        }
    }, [data])

    useEffect(() => {
        if (state?.refresh) {
            refetch()
        }
    }, [])



    const handleClick = (type, data) => {
        navigate('/scheme-detail-action/update', { state: { schemeDetailId: data.schemeDetailId } })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Scheme Details" breadcrumbItem={"Scheme Details List"} />
                    <Row className="mb-4">
                        <Col xl={12}>
                            <Card className="h-100 b-0">
                                <CardBody>
                                    <Row>
                                        <Col sm={6}>
                                            <Link to={`/scheme-detail/add`}><Button type='link' color='primary'>Create Scheme Detail</Button></Link>
                                        </Col>
                                        <Col sm={6}>

                                        </Col>
                                    </Row>
                                    <CustomTable
                                        column={SchemeDetailHeader.gridColumn()}
                                        dataSource={schemeData}
                                        handleClick={handleClick}
                                        xrow={SchemeDetailHeader.width}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {
                    (isLoading || schemeLoading) &&
                    <Loader />
                }
            </div>
        </React.Fragment>
    )
}

export default SchemeDetail