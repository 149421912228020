import { Routes, Route } from "react-router-dom";

import { authProtectedRoutes, publicRoutes } from "./routes";
import NonAuthLayout from "../layout/NonAuthLayout";
import Layout from "../layout/Layout";
import { AuthProtected, CheckAuth } from "./AuthProtected";

const Index = () => {

  return (
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <CheckAuth>
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
                </CheckAuth>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected>
                  <Layout>{route.component}</Layout>
                </AuthProtected>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
  );
};

export default Index;
