import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import { Form } from 'antd'
import SchemeForm from './SchemeForm'
import { useGetStateListQuery, useGetZoneListQuery } from '../../store/APIQuery/masterAPI'
import { useAddUpdateSchemeMutation } from '../../store/APIQuery/schemeAPI'
import Loader from '../../components/loader/Loader'
import { useNavigate } from 'react-router-dom'
import Popup from '../../components/popup/popup'

const SchemeAction = () => {
    const [{ showPopup, status, msg }, togglePopup] = useState({ showPopup: false, status: false, msg: "" })
    const navigate = useNavigate()
    const { isLoading: zoneLoading, isSuccess: zoneSuccess, data: zoneList } = useGetZoneListQuery()
    const { isLoading: stateLoading, isSuccess: stateSuccess, data: stateList } = useGetStateListQuery()

    const [addUpdateScheme, { isLoading, isError, data }] = useAddUpdateSchemeMutation()
    const handleSubmit = (param) => {
        addUpdateScheme(param)
    }

    useEffect(() => {
        if (data?.success == 'true' && data?.status == '200') {
            togglePopup({
                showPopup: true,
                status: true,
                msg: "Your data save successfully"
            });
        }
        else if (data?.success == "fail") {
            // togglePopup(true)
            togglePopup({
                showPopup: true,
                status: false,
                msg: "Some thing went wrong, Please try again later."
            });
        }
    }, [data])
    const closePopup = () => {
        togglePopup({
            showPopup: false,
            status: false,
            msg: ""
        })
        navigate('/scheme-master', { state: { refresh: true } })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs back title="Scheme" breadcrumbItem={"Create Scheme"} />
                    <Row className="mb-4">
                        <Col xl={12}>
                            <Card className="h-100 b-0">
                                <CardBody>
                                    <SchemeForm handleSubmit={handleSubmit} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {
                    (isLoading || zoneLoading || stateLoading) &&
                    <Loader />
                }
                <Popup
                    isOpen={showPopup}
                    isSuccess={status}
                    msg={msg}
                    closePopup={closePopup}
                    btnText='Close'
                />
            </div>
        </React.Fragment>
    )
}

export default SchemeAction