import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/auth/Login";
import DealerReg from "../pages/Dealer/DealerReg";
import DealerList from "../pages/Dealer/DealerList";
import Error404 from "../pages/Error404";
import DealerApplication from "../pages/Dealer/DealerApplication";
import SchemeMaster from "../pages/Scheme/Scheme";
import SchemeAction from "../pages/Scheme/SchemeAction";
import SchemeDetail from "../pages/SchemeDetail/SchemeDetail";
import SchemeDetailAction from "../pages/SchemeDetail/SchemeDetailAction";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "/dealer-registration",
    component: <DealerReg />,
  },
  {
    path: "/dealer-application",
    component: <DealerApplication />,
  },
  {
    path: "/dealer-list",
    component: <DealerList />,
  },
  {
    path: "/dealer-operation",
    component: <DealerReg />,
  },
  {
    path: "/scheme-master",
    component: <SchemeMaster />,
  },
  {
    path: "/scheme-action/:operation",
    component: <SchemeAction />,
  },
  {
    path: "/scheme-detail",
    component: <SchemeDetail />,
  },
  {
    path: "/scheme-detail/:operation",
    component: <SchemeDetailAction />,
  },
  {
    path: "/error-404",
    component: <Error404 />,
  },
  {
    path: "/*",
    component: <Navigate to="/error-404" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/login", component: <Login /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
];

export { authProtectedRoutes, publicRoutes };
