import { message } from "antd";
import { customRegExp } from "./helper";

const validationRules = {
    mobileValidation:[
        {
            pattern:customRegExp.number(),
            message:"Please enter numbers only"
        },
        {
            min:10,
            message:"Please enter 10 digit mobile number"
        },
        {
            max:10,
            message:"Please enter 10 digit mobile number"
        }
    ],
    gstValidation:[
        {
            pattern:customRegExp.alphanum(),
            message:"Special characters are not allowed"
        },
        {
            min:15,
            message:"Please enter valid GST number"
        },
        {
            max:15,
            message:"Please enter valid GST number"
        }
    ],
    panValidation:[
        {
            pattern:customRegExp.alphanum(),
            message:"Special characters are not allowed"
        },
        {
            min:10,
            message:"Please enter valid PAN number"
        },
        {
            max:10,
            message:"Please enter valid PAN number"
        }
    ],
    pincodeValidation:[
        {
            pattern:customRegExp.number(),
            message:"Please enter numbers only"
        },
        {
            min:6,
            message:"Please enter 6 digits pincode"
        },
        {
            max:6,
            message:"Please enter 6 digits pincode"
        }
    ],
    adharValidation:[
        {
            pattern:customRegExp.number(),
            message:"Please enter numbers only"
        },
        {
            min:12,
            message:"Please enter 12 digits adhaar number"
        },
        {
            max:12,
            message:"Please enter 12 digits adhaar number"
        }
    ],
    TextValidation:[
        {
            pattern:customRegExp.text(),
            message:"Please enter characters only"
        }
    ],
    AddressValidation:[
        {
            pattern:customRegExp.paragraph(),
            message:"Please enter characters only"
        }
    ],
    numberWithCommaValidation:[
        {
            pattern:customRegExp.numberWithComma(),
            message:"Please enter numbers with commas only"
        }
    ],
    numberValidation:[
        {
            pattern:customRegExp.number(),
            message:"Please enter numbers only"
        }
    ]
}

export default validationRules;