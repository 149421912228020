const SchemeHeader={
    PageName:'Scheme',
    pagination:true,
    horizontalScroll:'',
    fixHeader:true,
    gridColumn:()=>[
        {
            title:'Action',
            dataIndex:'action',
            type:'action',
            actionList:[
                {
                    title:'Edit',
                    key:'edit'
                }
            ],
            width:80,
            fixed: 'left',
        },
        {
            title: "Scheme Name",
            dataIndex: "schemeName",
            width:210
        },
        {
            title: "Scheme Type",
            dataIndex: "schemeType",
            width:140
        },
        {
            title: "Scheme Apply To Cat",
            dataIndex: "schemeApplyToCat",
            width:150
        },
        {
            title: "Period From - To",
            dataIndex: "period",
            width:150
        },
        // {
        //     title: "Valid From",
        //     dataIndex: "periodFrom",
        //     width:150
        // },
        // {
        //     title: "Valid till",
        //     dataIndex: "periodTo",
        //     width:150
        // },
        {
            title: "Scheme Appliable",
            dataIndex: "schemeAppliable",
            type:'status',
            width:100
        },
        {
            title: "Zone",
            dataIndex: "zone",
            width:150
        },
        {
            title: "State",
            dataIndex: "state",
            width:180
        },
        {
            title: "Status",
            dataIndex: "status",
            width:120
        }
    ]
}
export default SchemeHeader;