import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import SchemeHeader from '../../gridHeader/SchemeHeader'
import CustomTable from '../../components/table/CustomTable'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useGetSchemeListQuery } from '../../store/APIQuery/schemeAPI'
import Loader from '../../components/loader/Loader'
import { useGetStateListQuery } from '../../store/APIQuery/masterAPI'

const SchemeMaster = () => {
    let name="";
    const [schemeData, setSchemeList] = useState([])
    const {isLoading:stateLoading,data:stateList} = useGetStateListQuery()
    const {isLoading,isSuccess,data,refetch} = useGetSchemeListQuery(name,{skip:stateLoading})
    const navigate = useNavigate()
    const {state} = useLocation()
    
    useEffect(()=>{
        if(data?.status=="200" && data?.success=='true'){
            let list = [];
            data?.data.map((item)=>{
                let name = []
                stateList?.data.map((state)=>{
                    if(item.state.split(",").indexOf(state.stateId)>-1){
                        name.push(state.stateName)
                    }
                })
                list.push({
                    ...item,
                    state:name.join()
                })
            })
            
            setSchemeList(list)
        }
    },[data])

    useEffect(()=>{
        if(state?.refresh){
            refetch()
        }
    },[])

   
    
    

    const handleClick=(type,data)=>{
        navigate('/scheme-action/update',{state:{schemeId:data.schemeId}})
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Scheme" breadcrumbItem={"Scheme List"} />
                    <Row className="mb-4">
                        <Col xl={12}>
                            <Card className="h-100 b-0">
                                <CardBody>
                                    <div className="">
                                        <Link to={`/scheme-action/add`}><Button type='link' color='primary'>Create Scheme</Button></Link>
                                    </div>
                                    <CustomTable
                                        column={SchemeHeader.gridColumn()}
                                        dataSource={schemeData}
                                        handleClick={handleClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {
                    (isLoading) &&
                    <Loader />
                }
            </div>
        </React.Fragment>
    )
}

export default SchemeMaster