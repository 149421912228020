import { Button, DatePicker, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import FileBase64 from 'react-file-base64';
import ImageWithPreview from '../../components/Image/ImageWithPreview';
import { useGetStateListQuery, useGetZoneListQuery } from '../../store/APIQuery/masterAPI';
import { useGetSchemeDataMutation } from '../../store/APIQuery/schemeAPI';
import { useLocation, useParams } from 'react-router-dom';

const SchemeForm = (props) => {
    const [form] = Form.useForm()
    const {handleSubmit} = props;
    const [fileData, setFile]= useState("");
    const [zoneDropdown, setZoneDropdown]= useState([]);
    const [stateDropdown, setStateDropdown]= useState([]);
    const [dateRange, setDateRange]= useState([]);
    const [AppliedType, setAppliedType]= useState("");
    const {state} = useLocation();
    const {operation} = useParams()

    const {data:zoneList} = useGetZoneListQuery()
    const {data:stateList} = useGetStateListQuery()
    const [getSchemeData, {isLoading,data:updateData}] = useGetSchemeDataMutation()

    useEffect(()=>{
        if(zoneList?.data){
            let arr=[];
            zoneList.data.map((item)=>{
                arr.push({
                    label:item.zone,
                    value:item.id
                })
            })
            setZoneDropdown(arr)
        }
        if(stateList?.data){
            let arr=[];
            stateList.data.map((item)=>{
                arr.push({
                    label:item.stateName,
                    value:item.stateId
                })
            })
            setStateDropdown(arr)
        }
    },[zoneList, stateList])

    const handleRange=(data,str)=>{
        setDateRange(str)
    }

    const handleFinish=(formdata)=>{
        const {SchemeName,SchemeType,SchemeApplyToCat,SchemeAppliable,Zone="",State,period} = formdata
        let params = {
            SchemeName,
            SchemeType,
            SchemeApplyToCat,
            SchemeAppliable,
            Zone,
            State:State.join(),            
            PeriodFrom:dateRange[0],
            PeriodTo:dateRange[1],
            file:fileData,
            Mode:"I",
            SchemeId:"0"
        }

        handleSubmit(params)
        // if(formdata.SchemeAppliable=="SW"){
            
        // }
    }

    useEffect(()=>{
        if(operation=='update'){
            getSchemeData(state)
        }
    },[])
    return (
        <React.Fragment>
            <Form layout='vertical' onFinish={handleFinish}>
                <Row>
                    <Col sm={6}>
                        <Form.Item name={`SchemeName`} label="Scheme Name" rules={[{ required: true }]} >
                            <Input placeholder='enter Scheme Name' />
                        </Form.Item>
                    </Col>
                    <Col sm={3}>
                        <Form.Item name={`SchemeType`} label="Scheme Type" rules={[{ required: true }]} >
                            <Select placeholder="select scheme type">
                                <Select.Option value={`S`}>Seasonal</Select.Option>
                                <Select.Option value={`Y`}>Yearly</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col sm={3}>
                        <Form.Item name={`SchemeApplyToCat`} label="Category" rules={[{ required: true }]} >
                            <Select placeholder="select category" >
                                <Select.Option value={`1`}>Category A</Select.Option>
                                <Select.Option value={`2`}>Category B</Select.Option>
                                <Select.Option value={`3`}>Category C</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}>
                        <Form.Item name={`period`} label="Scheme Period"  rules={[{ required: true }]} >
                            <DatePicker.RangePicker style={{width: '100%'}} onChange={handleRange}/>
                        </Form.Item>
                    </Col>
                    <Col sm={3}>
                        <Form.Item name={`SchemeAppliable`} label="Scheme Applied On:" rules={[{ required: true }]} >
                            <Select placeholder="select type" onChange={(val)=>setAppliedType(val)}>
                                <Select.Option value={`ZW`}>Zone Wise</Select.Option>
                                <Select.Option value={`SW`}>State Wise</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {
                        AppliedType=='ZW' && 
                        <Col sm={3}>
                            <Form.Item name={`Zone`} label="Zone" rules={[{ required: true }]} >
                                <Select placeholder="select Zone" options={zoneDropdown}>
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    <Col sm={3}>
                        <Form.Item name={`State`} label="State" rules={[{ required: true }]} >
                            <Select placeholder="select State" options={stateDropdown} mode="multiple" allowClear>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col sm={4}>
                        <label>Scheme Photo</label>
                        {
                            fileData &&
                            <div className="text-center mb-3">
                                <ImageWithPreview src={fileData} />
                            </div>
                        }
                        <FileBase64
                            multiple={false}
                            onDone={(file) => setFile(file.base64)}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col sm={12} className="text-center">
                        <Button htmlType='submit' type="primary">Submit</Button>&nbsp;&nbsp;
                        {/* <Button type="primary" className='bg-success'>Hold</Button>&nbsp;&nbsp; */}
                        <Button type="primary" danger>Cancel</Button>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default SchemeForm