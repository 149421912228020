import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { apiURL, isDev } from '../config';

const axiosBaseQuery = (apiURL) => async ({ url, method, data, params, headers }) => {
    try {

        axios.defaults.baseURL = isDev?`${apiURL}/api`:`${apiURL}/MagicpaintsApi/api`;
        const result = await axios({
            url,
            method,
            data,
            params,
            headers
        });
        return result
    } catch (axiosError) {
        return {
            error: {
                status: axiosError.response?.status,
                data: axiosError.response?.data || axiosError.message,
            },
        };
    }
};

export const apiService = createApi({
    baseQuery: axiosBaseQuery(apiURL),
    endpoints: () => ({}),
    reducerPath: 'apiService'
})

export default apiService;