import {configureStore, createSelector} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import apiService from './apiService';
import { rootReducer } from './rootReducer';

const middlewares= [apiService.middleware];
export const makeStore = (preloadedState) => {
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware)=> getDefaultMiddleware().concat(middlewares),
        preloadedState
    })

    setupListeners(store.dispatch);
    return store;
}


export const store = makeStore();
export const createAppSelector = createSelector.withTypes();

export default store;
