import React from 'react'

const NonAuthLayout = (props) => {
  return (
    <React.Fragment>
          {props.children}
    </React.Fragment>
  )
}

export default NonAuthLayout