const SidebarData = [
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
    },
    // {
    //     label: "Calendar",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/#",
    // },
    {
        label: "Dealer",
        icon: "mdi mdi-account-cog-outline",
        subItem: [
            { sublabel: "New Dealer Registration", link: "/dealer-registration" },
            { sublabel: "Dealer's Application", link: "/dealer-application" },
            { sublabel: "Dealer's List", link: "/dealer-list" },
        ],
    },
    {
        label: "Painter",
        icon: "mdi mdi-account-multiple-outline",
        subItem: [
            { sublabel: "Painter Registration", link: "/painter-registration" },
            { sublabel: "Painter's List", link: "/painter-list" },
        ],
    },
    {
        label: "Master Setup",
        icon: "mdi mdi-cog-outline",
        subItem: [
            // { sublabel: "Category", link: "/painter-registration" },
            { sublabel: "Scheme Master", link: "/scheme-master" },
            { sublabel: "Scheme Detail", link: "/scheme-detail" },
        ],
    },
]
export default SidebarData;