

export const getUserId = () =>{
    return JSON.parse(localStorage.getItem('isLogin')).userId;
}

export const getUserRole = () =>{
    return JSON.parse(localStorage.getItem('isLogin')).userType;
}

export const customRegExp = {
    number: ()=> new RegExp(/^[0-9]+$/),
    alphanum: ()=> new RegExp(/^[a-zA-Z0-9]+$/),
    alphabet: ()=> new RegExp(/^[a-zA-Z ]+$/),
    numberWithComma: ()=> new RegExp(/^[0-9,]+$/),
    text: ()=> new RegExp(/^[a-zA-Z ,-]+$/),
    paragraph: ()=> new RegExp(/^[a-zA-Z ,-/0-9]+$/),
}