import React, { useEffect, useState } from 'react'
import SchemeDetailForm from './SchemeDetailForm'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../components/common/Breadcrumbs'
import Loader from '../../components/loader/Loader'
import { useGetSchemeListQuery } from '../../store/APIQuery/schemeAPI'
import { useAddUpdateSchemeDetailMutation } from '../../store/APIQuery/schemeDetailAPI'
import Popup from '../../components/popup/popup'
import { useNavigate } from 'react-router-dom'
import { useGetStateListQuery, useGetZoneListQuery } from '../../store/APIQuery/masterAPI'

const SchemeDetailAction = () => {
  const {isLoading:schemeLoading} = useGetSchemeListQuery()
  const [{ showPopup, status, msg }, togglePopup] = useState({ showPopup: false, status: false, msg: "" })
  const navigate = useNavigate()
  const [addUpdateSchemeDetail,{isLoading,isSuccess,data:sdData}] = useAddUpdateSchemeDetailMutation()
  const handleSubmit=(data,status)=>{
    if(status){
      togglePopup({
        showPopup: true,
        status: false,
        msg: "Please enter points in ascending order!"
    });
    }
    else{
      addUpdateSchemeDetail(data)
    }
   
  }
  const {data:zoneList} = useGetZoneListQuery()
  const {data:stateList} = useGetStateListQuery()

  useEffect(()=>{
    if(sdData?.success=='true' && sdData?.status=='200'){
      togglePopup({
        showPopup: true,
        status: true,
        msg: "Your data save successfully"
    });
    }
    else if (sdData?.success == "fail") {
      // togglePopup(true)
      togglePopup({
          showPopup: true,
          status: false,
          msg: "Some thing went wrong, Please try again later."
      });
  }
  },[sdData])
  const closePopup=()=>{
    
    togglePopup({
      showPopup: false,
      status: false,
      msg: ""
    })
    if(status)
    navigate('/scheme-detail',{state:{refresh:true}})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs back title="Scheme Detail" breadcrumbItem={"Create Scheme Detail"} />
          <Row className="mb-4">
            <Col xl={12}>
              <Card className="h-100 b-0">
                <CardBody>
                  <SchemeDetailForm handleSubmit={handleSubmit} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {
          (schemeLoading || isLoading) &&
          <Loader />
        }
        <Popup
              isOpen={showPopup}
              isSuccess={status}
              msg={msg}
              closePopup={closePopup}
              btnText='Close'
          />
      </div>
    </React.Fragment>
  )
}

export default SchemeDetailAction